export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function waitForRef(getVueInstance, refName, callback, checkInterval = 100) {
    const vueInstance = getVueInstance();
    if (vueInstance && vueInstance.$refs[refName]) {
        // console.log('🟢');
        callback();
    } else {
        // console.log('🔴');
        setTimeout(() => {
            waitForRef(getVueInstance, refName, callback, checkInterval);
        }, checkInterval);
    }
}