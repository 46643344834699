<template>
  <v-card class="pa-3" max-width="800">
    <div v-show="form.show">
      <div v-show="form.page == 1">
        <h1>{{ $t("linkDevice.title_link_device") }}</h1>
        <div class="pincode-container">
          <pincode-input value="" v-model="form.values.code" placeholder="" />
          <v-btn v-if="form.values.device === null" color="primary" @click="codeValid">{{ $t("linkDevice.next") }}</v-btn>
          <v-btn v-if="form.values.device !== null" color="primary" @click="submitForm">{{ $t("linkDevice.connect") }}</v-btn>
        </div>
      </div>

      <div v-show="form.page == 2">
        <h1>{{ $t("linkDevice.title_link_device") }}</h1>
          <div class="name-container">

          <v-autocomplete
            clearable
            label="Kies een apparaat"
            :items="deviceSelect"
            v-model="form.values.device"
            item-text="name"
            item-value="id"
          ></v-autocomplete>

          <v-btn color="primary" @click="submitForm">{{ $t("linkDevice.connect") }}</v-btn>
        </div>
      </div>
    </div>

    <div v-show="result.show">
      <p :class="{ success: result.success, error: !result.success }" class="message">
        {{ result.message }}
      </p>
      <router-link v-show="result.success" :to="{ name: 'gekoppeldeApparaten' }" class="text-deco-none"><v-btn>{{ $t("linkDevice.display_devices") }}</v-btn></router-link>
      <v-btn v-show="!result.success" @click="showForm">{{ $t("linkDevice.retry") }}</v-btn>
    </div>
  </v-card>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import PincodeInput from "vue-pincode-input";
import { mapGetters } from "vuex";
import { ILLI_API_SERVER_URL } from "../config";
import { delay } from "../utils/utils";

export default Vue.extend({
  components: { PincodeInput },
  data: () => ({
    form: {
      show: true,
      page: 1,
      values: {
        code: "",
        deviceName: "",
        device: null
      },
    },
    result: {
      show: false,
      success: false,
      message: "",
    },
    deviceSelect: [],
    devices: [],
  }),
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      system: "system/system",
    }),
  },
  created() {
    // get linked devices
    this.loadDevices()
      .then(() => {

        const params = this.$route.params;
        console.log({params});

        // if only one device, select it
        if (this.devices.length == 1) this.form.values.device = this.devices[0].stream_id;
    
        if (Object.keys(params).length){
          if (params.code){
    
            this.form.values.code = params.code;
            this.codeValid();
    
          } else if (params.stream){
    
            this.form.values.device = parseInt(params.stream);
            
          }
        }

      })
      .catch(() => {
        console.error('Could not load devices');
      });

  },
  methods: {
    showResult(success, message = null) {
      this.result.success = success;
      this.result.message = success ? "Apparaat gekoppeld!" : "Er ging iets fout";
      if (message) this.result.message = message;
      this.result.show = true;
      this.form.show = false;
    },
    showForm() {
      this.form.show = true;
      this.result.show = false;
    },
    codeValid() {
      console.log("code valid", this.form.values.code);
      const vue = this;
      axios
        .post(`${localStorage.getItem('stream-server')}/codeValid`, {
          code: this.form.values.code,
          accessToken: window.localStorage.getItem("token"),
          refreshToken: window.localStorage.getItem("refresh"),
        })
        .then(function (response) {
          console.log("validcode", { response });

          if (response.data == "success") {
            vue.form.page = 2;
            console.log("next");
          } else {
            vue.form.values.code = "";
            console.log("nope");
            vue.showResult(false, 'Code wordt niet herkend.');
          }
        })
        .catch(function (error) {
          console.log("validcode", { error });
          vue.form.values.code = "";
          console.log("nope2");
        });
    },
    linkDevice(code) {
      console.log({code});
      const vue = this;

      const device = {
        id: vue.form.values.device,
        name: this.devices.filter(e => e.stream_id == vue.form.values.device)[0].name
      }

      if (device.id !== null){
        // link device
        axios
          .post(`${ILLI_API_SERVER_URL}/stream/account/link`, {
            streamId: device.id,
            userId: vue.user.permissions.user_id,
          })
          .then((responsePlatform) => {
  
            if (responsePlatform.data.success) {
              
              const refreshToken = responsePlatform.data.result.refreshToken;
              const accessToken = responsePlatform.data.result.accessToken;
  
              // authenticate at server
              axios
                .post(`${localStorage.getItem('stream-server')}/authenticate`, {
                  code: code,
                  user: {
                    id: this.user.permissions.user_id,
                    name: this.user.name,
                  },
                  device: device,
                  accessToken: accessToken,
                  refreshToken: refreshToken,
                })
                .then(function (responseServer) {
                  // authentication successful
                  console.log({responseServer});
                  const success = responseServer.data == "success";
                  vue.showResult(success);

                  delay(1500).then(() => {
                    vue.$router.push({ name: "manageLinkedDevice", params: { id: device.id } });
                  });
                })
                .catch(function (err) {
                  // authentication fails, unlink @ platform
                  console.warn({err});
                  axios.delete(`${ILLI_API_SERVER_URL}/stream/${device.id}`).then(() => {
                    vue.showResult(false);
                  })
                });
            } else {
              vue.showResult(false);
            }
            
          }).catch((error) => {
            console.warn({error});
            vue.showResult(false, error.response.data.message);
          })

      } else {
        vue.showResult(false, 'Naam niet geldig.')
        vue.form.page = 1;
      }

    },
    submitForm() {
      console.warn('SUBMIT FORM', this.form.values.code);
      this.linkDevice(this.form.values.code);
    },

    loadDevices() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${ILLI_API_SERVER_URL}/stream/accounts`)
          .then((result) => {
  
            this.devices = result.data.accounts.filter((account) => {
              const isParent = result.data.accounts.some((a) => a.parent_stream_id == account.stream_id);
              return !isParent;
            });
  
            this.deviceSelect  = this.devices.map((device) => {
              const activeStr = (device.active) ? ' (actief)' : '';
              return {id: device.stream_id, name: `${device.name}${activeStr}`}
            });

            resolve();
          
          })
          .catch((err) => {
            console.warn({err});
            reject();
          });
      });
    },

  },
});
</script>

<style>
.pincode-container {
  margin: 2vh;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pincode-container .v-btn {
  margin-top: 2vh;
  width: 100%;
}

.message{
  padding: 5px;
}

.success {
  background: rgb(104, 255, 104);
}

.error {
  background: rgb(255, 126, 126);
}

.text-deco-none{
  text-decoration: none;
}
</style>